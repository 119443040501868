import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import BaseStyles from "styles/BaseStyles";
import ErrorBoundaryWrap from "components/ErrorBoundary";
import Layout from "components/Layout";
import { useNetwork, useSwitchNetwork } from "wagmi";
import { mainChain } from "index";
import StakingPools from "pages/Staking";

const App = () => {
  const { chain } = useNetwork();
  const { pathname } = useLocation();
  const { switchNetwork } = useSwitchNetwork();
  useEffect(() => {
    const chainId: any = mainChain?.id;
    switchNetwork?.(chainId);
  }, [pathname, switchNetwork, chain]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);

  return (
    <Layout>
      <ErrorBoundaryWrap>
        <Routes>
          <Route index element={<StakingPools />} />
        </Routes>
        <Toaster position="top-right" />
        <BaseStyles />
      </ErrorBoundaryWrap>
    </Layout>
  );
};

export default App;
