import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
.ReactModalPortal{
  overflow: hidden;
  .ReactModal__Overlay {
    
    z-index: 1000;
    background: rgba(33, 33, 33, 0.3) !important;
    backdrop-filter: blur(5px);
    padding: 70px 0;  
    overflow: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100vw;
    .ReactModal__Content{
    inset: auto !important;
    outline: none;
    max-width: 604px;
    margin: auto;
    border: none !important;
    border-radius: 32px !important;
    max-width: 100%;
    padding: 16px 0 !important;
    margin: 0px auto;
    position: relative !important;
    min-height: 366px;
    width: 100%;
    max-width: 500px;
    background: #fff !important;
    overflow: unset !important;
    
    &:after{
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      background-size: 100%;
    }
    &.disconnect{
      max-width: 400px;
    }
      }
      .modal-head{
        padding: 0 20px 16px 20px !important;
        border-bottom: 1px solid rgb(228 228 228);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title{
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          color: #000000DE;
          text-transform: capitalize;
        }
        .modal-close{
          cursor: pointer;
        }
      }
      .modal-content{
        
      }
      .modal-footer{
        display: flex;
        gap: 10px;
        padding-top: 20px;
      }
     
    }
  
}
@media only screen and (max-width: 695px){
  .ReactModalPortal {
    .ReactModal__Overlay {
      .ReactModal__Content{
        margin-right: 30px;
        &:after{
          border-radius: 16px;
        }
      }
    }
  }
}
@media only screen and (max-width: 479px){
  .ReactModalPortal {
    .ReactModal__Overlay{
      max-width: 100%;
      .ReactModal__Content{
        padding: 16px;
        &:after{
          background-size: cover !important;
          border-radius: 16px;
        }
  
        .modal-head {
          padding: 0 16px 8px 16px !important;
          .title{
            font-size: 18px;
          }
        }
      }
    }
  }
}


`;
