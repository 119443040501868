import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import LoadingLayout from "components/LoadingLayout";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import "@rainbow-me/rainbowkit/styles.css";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { avalancheFuji, avalanche } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { myCustomTheme } from "theme";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import {
  injectedWallet,
  rainbowWallet,
  walletConnectWallet,
  coinbaseWallet,
  metaMaskWallet,
  trustWallet
} from "@rainbow-me/rainbowkit/wallets";
import GlobalStyle from "Global";
import { bsc } from "viem/chains";

const REACT_APP_CHAIN_TYPE = process.env.REACT_APP_CHAIN_TYPE;

const CustomAvatar = ({ size }: { size: number }) => {
  return (
    <img
      src={"/images/logo.png"}
      alt=""
      width={86}
      style={{
        borderRadius: "50%",
        objectFit: "cover",
        objectPosition: "center"
      }}
    />
  );
};

export const mainChain = bsc;

const { chains, publicClient } = configureChains(
  [mainChain],
  [publicProvider()]
);

const projectId = "4f8e8a0f2ae755e95b2c711de2685ad7";

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      metaMaskWallet({ chains, projectId }),
      trustWallet({ chains, projectId }),
      injectedWallet({ chains }),
      coinbaseWallet({ appName: "Staking", chains }),
      rainbowWallet({ chains, projectId }),
      walletConnectWallet({ chains, projectId })
    ]
  }
]);

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  }
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <QueryClientProvider client={queryClient}>
    <GlobalStyle />
    <Suspense fallback={<LoadingLayout />}>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider
          theme={myCustomTheme}
          chains={chains}
          avatar={({ size }) => <CustomAvatar size={size} />}
        >
          <Router>
            <App />
          </Router>
        </RainbowKitProvider>
      </WagmiConfig>
    </Suspense>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
