import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: "BaiJamjuree-Regular";
  src: url("/fonts/BaiJamjuree-Regular.ttf");
}

@font-face {
  font-family: "BaiJamjuree-Medium";
  src: url("/fonts/BaiJamjuree-Medium.ttf");
}

@font-face {
  font-family: "BaiJamjuree-SemiBold";
  src: url("/fonts/BaiJamjuree-SemiBold.ttf");
}

@font-face {
  font-family: "BaiJamjuree-Bold";
  src: url("/fonts/BaiJamjuree-Bold.ttf");
}




    * {
       
        box-sizing: border-box;
        ::-webkit-scrollbar-thumb {
            background: #000; 
        }
        button{
          border: none;
          outline: none;
        }
        p{
          margin-block-start: 0;
          margin-block-end: 0;
        }
        ul{
            margin-block-start: 0px;
            margin-block-end: 0px;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            padding-inline-start: 0px;
            li{
              list-style: none;
            }
        }
        a{
          text-decoration: none;
        }
    }
  body {
    background: linear-gradient(70.11deg,rgba(198,235,255,0.5) 0.4%,rgba(219,238,255,0.5) 22.71%,rgba(198,235,255,0.5) 49.68%,rgba(234,231,255,0.5) 75.62%,rgba(247,247,221,0.5) 93.26%),#fff;
    padding: 0;
    margin: 0;
    color: #000;
    font-family: "BaiJamjuree-Regular";
    img {
      height: auto;
      max-width: 100%;
    }

    
  }

  .container{
    max-width: 1110px;
    padding: 0 15px;
    margin:  0 auto;
  }
 `;

export default GlobalStyle;
