const REACT_APP_SOCIAL_SCAN_CONTRACT =
  process.env.REACT_APP_SOCIAL_SCAN_CONTRACT;
const REACT_APP_SOCIAL_COINGECKO = process.env.REACT_APP_SOCIAL_COINGECKO;
const REACT_APP_SOCIAL_CMC = process.env.REACT_APP_SOCIAL_CMC;
const REACT_APP_SOCIAL_TELEGRAM = process.env.REACT_APP_SOCIAL_TELEGRAM;
const REACT_APP_SOCIAL_TWITTER = process.env.REACT_APP_SOCIAL_TWITTER;

export const FILTER_DEFAULT = {
  page: 1,
  take: 100
};

export const MAX_INT =
  "115792089237316195423570985008687907853269984665640564039457584007913129639935";

export const FORMAT_TIME_DATE = "YYYY-MM-DD HH:mm:ss";
export const FORMAT_DATE = "YYYY-MM-DD";

export const TOTAL_POOL_1 = 512000000000000;
export const FEE = "0.0015625";
export const TOTAL_POOL_2 = 448000000000000;
export const AMOUNT_EXCHANGE_TICK = 500000000000;
export const APR_POOL_3 = 5215;

export const socialLink = {
  twitter: REACT_APP_SOCIAL_TWITTER,
  telegram: REACT_APP_SOCIAL_TELEGRAM,
  scan: REACT_APP_SOCIAL_SCAN_CONTRACT,
  coingecko: REACT_APP_SOCIAL_COINGECKO,
  cmc: REACT_APP_SOCIAL_CMC
};
