import styled from "styled-components";

export const Styles = styled.button`
  display: inline-flex;
  justify-content: center;
  white-space: nowrap;
  align-items: center;
  cursor: pointer;
  transform: translate(0, 0);
  transition: all 0.3s;
  &:disabled {
    background: #99cdf8;
    cursor: not-allowed;
    opacity: 0.8;
  }
  &:hover:not(:disabled) {
    box-shadow: 0px 0px 0px 0px #000;
  }
`;
