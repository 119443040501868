const formatCurrency = (value: any, fixed = 4) => {
  if (value || +value === 0) {
    const result = +Number(value).toFixed(fixed);
    if (result % 1 === 0)
      return result.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    return result.toString().replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }
  return "";
};

const processTime = (second: number) => {
  const distance = second * 1000;
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
};
export const formatNumberStrinhToNumber = (
  numberString: string,
  symbol: string = ","
) => numberString.replaceAll(symbol, "");

export const processTimeToDisplay = (milisecond: number) => {
  const distance = milisecond;
  const days = Math.floor(distance / (24 * 60 * 60 * 1000));
  const hours = Math.floor(
    (distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  return {
    minutes,
    seconds,
    hours,
    days
  };
};

export const removePropertiesEmpty = (object: any) => {
  let objectToProcess = { ...object };
  for (let i in objectToProcess) {
    if (objectToProcess[i] === "") {
      delete objectToProcess[i];
    }
  }
  return objectToProcess;
};

const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const findIndex = (id: string, array: any[]) => {
  let index = -1;
  for (let i = 0; i < array.length; i++) {
    if (array[i].id === id) {
      index = i;
      break;
    }
  }
  return index;
};

const configErrorMessage = (error: any) => {
  return (
    error?.response?.data?.message ||
    error?.response?.data?.message?.[0] ||
    error?.response?.data?.error ||
    error?.message ||
    "Error!"
  );
};

const hiddenAddress = (address: string | undefined, number: number = 7) => {
  if (address) {
    return `${address.slice(0, number)}...${address.slice(-1 * number)}`;
  }
  return "";
};

const removeAccents = (str: string) => {
  return str
    .toLocaleUpperCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/Đ/g, "D");
};

const sliceString = (string: string | undefined, numberSlice = 4) => {
  if (string) {
    return `${string.slice(0, numberSlice)}...${string.slice(
      numberSlice * -1
    )}`;
  }
  return "";
};

export {
  formatCurrency,
  scrollTop,
  findIndex,
  configErrorMessage,
  hiddenAddress,
  sliceString,
  processTime,
  removeAccents
};
