import moment from "moment";

export const formatDate = (
  date: string | number | Date,
  formatStr = "yyyy/MM/DD"
) => (date ? moment(new Date(date)).format(formatStr) : date);
export const getDate = (dateString: any) => {
  if (dateString) {
    let date = new Date(dateString);
    return date.getDate();
  }
  return "";
};

export const formatDateTitleRound = (startTime: string, endTime: string) => {
  const timeStart = moment(new Date(startTime)).utcOffset(0);
  const timeEnd = moment(new Date(endTime)).utcOffset(0);
  return `${timeStart.hours().toString().padStart(2, "0")}:${timeStart
    .minutes()
    .toString()
    .padStart(2, "0")} - ${timeEnd
    .hours()
    .toString()
    .padStart(2, "0")}:${timeEnd
    .minutes()
    .toString()
    .padStart(2, "0")} ${timeStart.format("MMMM")} ${timeStart.format(
    "Do"
  )} (UTC)`;
};

export const formatDateToRequest = (
  date: string | number | Date,
  formatStr = "yyyy/MM/DD"
) => (date ? moment(new Date(date)).format(formatStr) : date);

export const formatDateTime = (
  date: string | number | Date,
  formatStr = "yyyy/MM/DD HH:mm:ss"
) => (date ? moment(new Date(date)).format(formatStr) : date);
export const formatDateTimeTable = (
  date: string | number | Date,
  formatStr = "yyyy/MM/DD HH:mm:ss"
) => (date ? moment(new Date(date)).format(formatStr) : date);
export const formatTime = (
  date: string | number | Date,
  formatStr = "HH:mm DD/MM/yyyy"
) => (date ? moment(new Date(date)).format(formatStr) : date);

export const formatDatetimeToUnixTime = async (
  timeParams: any,
  dateParams: any
) => {
  let time = await formatTime(timeParams, "HH:mm:ss");
  let date = await formatTime(dateParams, "yyyy/MM/DD");
  let timeTotal = new Date(`${date} ${time}`).getTime();
  return Math.floor(timeTotal / 1000);
};
