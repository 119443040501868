import styled from "styled-components";

export const Styles = styled.div`
  padding-left: 140px;
  .children {
    padding: 30px 0 50px 0;
  }

  @media only screen and (max-width: 991px) {
    padding-left: 0;
    padding-top: 62px;
  }
`;
