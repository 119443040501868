import styled, { keyframes } from "styled-components";

const loading = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Styles = styled.div<{ width: string }>`
  display: flex;
  margin-right: 10px;
  &.special {
    .loading-run {
      border-color: ${(props) => props.theme?.main?.primaryColor};
      border-top-color: #fff;
      border-width: 3px;
      width: 40px;
      height: 40px;
    }
  }
  .loading-run {
    width: 20px;
    height: 20px;
    border-left: 2.5px solid
      ${(props) => (props.color ? props.color : "#1f1d2c")};
    border-right: 2.5px solid
      ${(props) => (props.color ? props.color : "#1f1d2c")};
    border-bottom: 2.5px solid
      ${(props) => (props.color ? props.color : "#1f1d2c")};
    border-top: 2.5px solid #fff;
    border-radius: 50%;
    animation: ${loading} 1s infinite linear;
  }
`;
