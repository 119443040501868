import ReactModal from "react-modal";
import { useState, useEffect } from "react";
import Styles from "./Styles";
import Button from "components/Button";
import { IoCloseSharp } from "react-icons/io5";

ReactModal.setAppElement("#root");

const Modal = ({
  children,
  toggleModal,
  setToggleModal,
  style,
  title,
  acceptFn,
  acceptTitle,
  acceptDisabled,
  loading,
  className,
  footer = false,
}: {
  title?: string;
  children: JSX.Element;
  toggleModal: boolean;
  setToggleModal: (value: boolean) => void;
  style?: any;
  acceptFn?: Function;
  acceptTitle?: string;
  loading?: boolean;
  className?: string;
  footer?: boolean;
  acceptDisabled?: boolean;
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(toggleModal);
    const body: any = document.getElementsByTagName("body")[0];
    if (toggleModal) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
    return () => {
      body.style.overflow = "auto";
    };
  }, [toggleModal]);

  function closeModal() {
    const body: any = document.getElementsByTagName("body")[0];
    setToggleModal(false);
    body.style.overflow = "auto";
  }

  return (
    <>
      <Styles />
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={style}
        className={className ? className : ""}
      >
        {title && (
          <div className="modal-head">
            <p className="title">{title}</p>
            <span className="modal-close" onClick={() => setToggleModal(false)}>
              <IoCloseSharp size={28} />
            </span>
          </div>
        )}

        <div className="modal-content">{children}</div>
        {footer && (
          <div className="modal-footer">
            <Button
              className="close__btn"
              type="button"
              onClick={() => setToggleModal(false)}
            >
              Cancel
            </Button>
            {acceptFn && (
              <Button
                color="primary"
                type="button"
                onClick={acceptFn}
                loading={loading}
                disabled={acceptDisabled}
              >
                {acceptTitle}
              </Button>
            )}
          </div>
        )}
      </ReactModal>
    </>
  );
};

export default Modal;
