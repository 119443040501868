import { useState } from "react";
import { StyledSidebar } from "./styles";
import { Link } from "react-router-dom";
import { IoWallet } from "react-icons/io5";
import { HiBars3 } from "react-icons/hi2";
import classNames from "classnames";
import { ButtonConnect } from "pages/Staking";
const Sidebar = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <StyledSidebar className={classNames({ isOpen })}>
      <div className="module">
        <div className="top">
          <div className="left">
            <span className="toggle" onClick={() => setOpen(!isOpen)}>
              <HiBars3 />
            </span>
            <Link to="" className="logo">
              <img src="/images/logo.png" alt="" />
            </Link>
          </div>
          <ButtonConnect />
        </div>
        <hr />
        <div className={`list-nav `}>
          <div className="nav">
            <div className="nav-content">
              <IconNav />

              <p className="text">Staking</p>
            </div>
          </div>
        </div>
      </div>
    </StyledSidebar>
  );
};

export default Sidebar;

const IconNav = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2537 4.6562C18.2806 4.64047 18.303 4.61796 18.3185 4.5909C18.3341 4.56384 18.3422 4.53318 18.3422 4.50197C18.3422 4.47077 18.3341 4.4401 18.3185 4.41304C18.303 4.38598 18.2806 4.36347 18.2537 4.34774L11.4356 0.386526C10.9993 0.133658 10.504 0.000488281 9.99979 0.000488281C9.49555 0.000488281 9.00025 0.133658 8.564 0.386526L1.74726 4.34774C1.7203 4.36347 1.69794 4.38598 1.6824 4.41304C1.66686 4.4401 1.65868 4.47077 1.65868 4.50197C1.65868 4.53318 1.66686 4.56384 1.6824 4.5909C1.69794 4.61796 1.7203 4.64047 1.74726 4.6562L9.91074 9.4584C9.9382 9.47458 9.96949 9.48311 10.0014 9.48311C10.0332 9.48311 10.0645 9.47458 10.092 9.4584L18.2537 4.6562ZM0.983045 5.87081C0.955816 5.85509 0.924919 5.84684 0.893478 5.84689C0.862036 5.84694 0.831166 5.85529 0.803988 5.8711C0.77681 5.88691 0.754288 5.90961 0.7387 5.93691C0.723112 5.96422 0.71501 5.99516 0.715214 6.0266V13.7888C0.715888 14.1628 0.814476 14.5302 1.00117 14.8543C1.18787 15.1785 1.45617 15.4481 1.77939 15.6364L9.01797 19.9766C9.0451 19.9922 9.07588 20.0005 9.1072 20.0005C9.13853 20.0005 9.16931 19.9923 9.19645 19.9766C9.22358 19.961 9.24613 19.9384 9.26181 19.9113C9.2775 19.8842 9.28577 19.8534 9.2858 19.8221V10.8163C9.28577 10.785 9.2775 10.7542 9.26183 10.7271C9.24616 10.7 9.22364 10.6775 9.19652 10.6619L0.983045 5.87081ZM10.7142 10.8476V19.8199C10.7143 19.8512 10.7225 19.882 10.7382 19.9091C10.7539 19.9362 10.7765 19.9587 10.8036 19.9744C10.8307 19.99 10.8615 19.9983 10.8928 19.9983C10.9242 19.9982 10.9549 19.99 10.9821 19.9743L18.2202 15.6341C18.5432 15.4461 18.8114 15.1769 18.9981 14.8532C19.1849 14.5294 19.2837 14.1625 19.2848 13.7888V6.0266C19.2847 5.99531 19.2764 5.9646 19.2607 5.93754C19.2449 5.91049 19.2224 5.88803 19.1953 5.87244C19.1681 5.85684 19.1374 5.84864 19.1061 5.84867C19.0748 5.8487 19.0441 5.85695 19.017 5.87259L10.8035 10.6936C10.7765 10.7092 10.754 10.7316 10.7383 10.7586C10.7226 10.7857 10.7143 10.8163 10.7142 10.8476Z"
        fill="#0076FF"
      />
    </svg>
  );
};
