import styled from "styled-components";
export const Styles = styled.div`
  font-family: "BaiJamjuree-Regular";
  min-height: 95vh;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 32px;
      font-family: "BaiJamjuree-Bold";
    }
    .btn_connect {
      padding: 11px 22px;
      border-radius: 10px;
      background: #0076ff;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      font-size: 16px;
      font-family: "BaiJamjuree-Bold";
      color: #fff;
      cursor: pointer;
      transition: all 0.2s linear;
      svg {
        width: 20px;
        fill: #fff;
      }

      &:hover {
        opacity: 0.85;
      }
    }
  }
  .tab-module {
    margin-top: 32px;
    background: #fff;
    border-radius: 20px;
    padding: 16px 24px;
    .tab-content {
      display: inline-flex;
      background: #eeeeee;
      border-radius: 16px;
      .item {
        padding: 8px 16px;
        cursor: pointer;
        p {
          font-size: 16px;
          font-family: "BaiJamjuree-SemiBold";
        }
        &.active {
          background: #0076ff;
          border-radius: 16px;
          p {
            color: #fff;
          }
        }
      }
    }
  }

  .content-module {
    margin-top: 20px;
    .list-item {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .item-group {
        .expansion-panel-header {
          cursor: pointer;
          border-radius: 20px;
          background-color: #fff;
          padding: 20px;
          display: flex;
          align-items: center;
          gap: 12px;
          justify-content: space-between;

          &.isOpen {
            border-radius: 20px 20px 0 0;
            .right {
              .icon-action {
                transform: rotate(180deg);
              }
            }
          }
          .token {
            display: flex;
            align-items: center;
            gap: 16px;
            .token-img {
              position: relative;
              width: 36px;
              height: 36px;
              img {
                width: 36px;
                &.child {
                  position: absolute;
                  width: 23px;
                  height: 23px;
                  border-radius: 50%;
                  border: 2px solid #fff;
                  top: 0;
                  right: 0;
                  transform: translate(40%, -40%);
                }
              }
            }
            .token-info {
              .name {
                font-size: 20px;
                font-family: "BaiJamjuree-SemiBold";

                line-height: 110%;
                white-space: nowrap;
              }
              .text {
                color: #868686;
                font-size: 14px;
                white-space: nowrap;
              }
            }
          }
          .right {
            display: flex;
            align-items: center;
            gap: 12px;
          }
          .info-group {
            display: flex;
            gap: 60px;
            .info {
              cursor: pointer;
              text-align: right;
              .text-title {
                font-size: 12px;
                font-family: "BaiJamjuree-SemiBold";
              }
              .text-info {
                font-size: 16px;
                font-family: "BaiJamjuree-SemiBold";

                display: flex;
                gap: 4px;
                align-items: center;
                justify-content: flex-end;
                line-height: 150%;

                .token {
                  display: inline-block;
                  width: 16px;
                  height: 16px;
                  border-radius: 50%;
                  overflow: hidden;
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }

                &.apr {
                  color: #1cb296;
                  white-space: nowrap;
                }
              }
              .text {
                margin-top: 2px;
                font-size: 15px;
                line-height: 100%;
                color: #868686;
              }
            }
          }
        }
        .expansion-panel-content {
          padding: 20px;
          border-radius: 0 0 20px 20px;
          background: #f3f5f7;
          justify-content: space-between;
          gap: 20px;
          display: none;
          &.isOpen {
            display: flex;
          }
          .left {
            display: flex;
            flex-direction: column;
            gap: 10px;
            .info {
              .text-title {
                font-size: 12px;
                color: #868686;
              }
              .text-amount {
                font-size: 20px;
                font-family: "BaiJamjuree-SemiBold";

                line-height: 120%;
              }
            }
            .list-link {
              display: flex;
              flex-direction: column;
              .link {
                font-size: 12px;
                color: #0076ff;
                font-family: "BaiJamjuree-SemiBold";
                line-height: 150%;
              }
            }
            .active {
              width: 80px;
              height: 30px;
              border-radius: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 4px;
              background: rgba(28, 178, 150, 0.1);
              svg {
                fill: #1cb296;
              }
              p {
                font-size: 12px;
                color: #1cb296;
              }
            }
          }
          .right {
            display: flex;
            gap: 20px;
            .item {
              padding: 8px;
              min-width: 231px;
              border: thin solid rgba(0, 0, 0, 0.12);
              border-radius: 20px;
              text-align: center;
              display: flex;
              flex-direction: column;
              gap: 8px;
              .item-title {
                font-size: 12px;
                font-family: "BaiJamjuree-SemiBold";
              }
              .item-amount {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 6px;
                font-size: 24px;
                font-family: "BaiJamjuree-Bold";
                line-height: 120%;
                .token {
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  overflow: hidden;
                  transform: translateY(-2px);

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
              }
              .item-text {
                height: 22px;
                font-size: 12px;
                color: #868686;
              }
              .btn {
                padding: 9px 22px;
                border-radius: 12px;
                background: #0076ff;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                font-size: 16px;
                font-family: "BaiJamjuree-Bold";
                color: #fff;
                cursor: pointer;
                transition: all 0.2s linear;
                svg {
                  width: 20px;
                  fill: #fff;
                }

                &:hover {
                  opacity: 0.85;
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .header {
      .btn_connect {
        display: none;
      }
    }
    .tab-module {
      margin-top: 16px;
      padding: 16px;
      .tab-content {
        display: flex;
        .item {
          width: 100%;
          display: flex;
          justify-content: center;
          p {
            font-size: 14px;
          }
        }
      }
    }
    .content-module {
      .list-item {
        .item-group {
          .expansion-panel-header {
            .right {
              .info-group {
                gap: 16px;
                .info {
                  &:nth-child(1),
                  &:nth-child(2),
                  &:nth-child(3) {
                    display: none;
                  }
                }
              }
            }
          }
          .expansion-panel-content {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .left {
              text-align: center;
            }
            .right {
              flex-wrap: wrap;
              justify-content: center;
            }
          }
        }
      }
    }
  }
`;

export const StyledStaking = styled.div`
  .staking-top {
    padding: 16px 24px;
    border-bottom: 1px solid rgb(228 228 228);
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text {
        font-size: 16px;
        color: #000000de;
        font-family: "BaiJamjuree-Bold";
      }
    }
    .input-group {
      margin-top: 8px;
      position: relative;
      input {
        width: 100%;
        height: 40px;
        border-radius: 10px;
        background: #eeeeee;
        border: none;
        outline: none;
        padding: 0 12px;
        font-size: 20px;
        font-family: "BaiJamjuree-Bold";
        padding-right: 60px;
      }

      .btn-max {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 50px;
        font-size: 18px;
        font-family: "BaiJamjuree-Medium";
        position: absolute;
        top: 4px;
        right: 8px;
        border-radius: 6px;
        background: #0076ff;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .staking-content {
    padding: 24px;
    h3 {
      font-size: 16px;
      color: #000000de;
      font-family: "BaiJamjuree-Bold";
    }
    .tx-danger {
      text-align: center;
      font-size: 12px;
      margin: 20px 0 5px;
      color: #c33838;
    }
    .card {
      margin-top: 8px;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0;
      box-shadow: #eeeeee 0px 0px 16px 0px;
      overflow: hidden;
      .card-title {
        padding: 8px 0;
        font-size: 18px;
        color: #fff;
        font-family: "BaiJamjuree-Bold";
        text-align: center;
        background: #0076ff;
      }
      .card-content {
        padding: 20px;
        .top {
          display: flex;
          flex-direction: column;
          gap: 8px;
          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .ct-left {
              font-size: 16px;
              color: #000000de;
              font-family: "BaiJamjuree-Bold";
            }
            .ct-right {
              font-size: 16px;
              color: #1cb296;
              font-family: "BaiJamjuree-Bold";
            }
          }
        }
        .bottom {
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-top: 8px;
          padding-top: 8px;
          border-top: 1px solid rgb(228 228 228);
          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .ct-left {
              font-size: 14px;
              color: #868686;
              font-family: "BaiJamjuree-SemiBold";
            }
            .ct-right {
              font-size: 14px;
              color: #000000de;
              font-family: "BaiJamjuree-SemiBold";
            }
          }
        }
      }
    }
  }

  .bt {
    button {
      width: 100%;
      height: 40px;
      border-radius: 12px;
      background: rgb(0, 118, 255);
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      gap: 8px;
      font-size: 16px;
      font-family: BaiJamjuree-Bold;
      color: rgb(255, 255, 255);
      cursor: pointer;
      transition: all 0.2s linear 0s;

      &:disabled {
        cursor: no-drop;
      }
    }
    padding: 0 24px;
  }

  @media only screen and (max-width: 480px) {
    .staking-top {
      padding: 16px;
    }
    .staking-content {
      padding: 20px 16px;
      .card {
        .card-content {
          padding: 20px 10px;
        }
      }
    }
    .btn {
      padding: 0 16px;
      button {
        height: 42px;
      }
    }
  }
`;
