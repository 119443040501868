import { Styles } from "./Styles";

const Loader = ({ special, ...rest }: any) => {
  return (
    <Styles className={special ? "special" : ""} {...rest}>
      <div className="loading-run"></div>
    </Styles>
  );
};

export default Loader;
