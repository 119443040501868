import Loader from "components/Loader";
import { Styles } from "./Styles";

const Button = ({ children, loadingColor, ...rest }: any) => {
  return (
    <Styles
      disabled={rest?.loading || rest.disabled}
      type="button"
      className={`btn_submit ${rest.className || ""} ${rest.size || ""}`}
      {...rest}
    >
      {rest?.loading && (
        <Loader
          color={
            loadingColor
              ? loadingColor
              : rest?.color !== "primary"
              ? "#000"
              : null
          }
        />
      )}{" "}
      {children}
    </Styles>
  );
};

export default Button;
