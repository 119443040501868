import {
  useAccount,
  useContractRead,
  useContractWrite,
  useWaitForTransaction
} from "wagmi";
import ERC20_ABI from "abi/ERC20_ABI.json";
import { MAX_INT } from "constant";

const useApprovalToken = (tokenAddress: any, contractAddress: string) => {
  const { address } = useAccount();
  const { data: allowance } = useContractRead({
    address: tokenAddress,
    abi: ERC20_ABI,
    functionName: "allowance",
    args: [address, contractAddress],
    watch: true,
    enabled: !!address && !!contractAddress && !!tokenAddress
  });
  const { writeAsync, isLoading, data } = useContractWrite({
    address: tokenAddress,
    abi: ERC20_ABI,
    functionName: "approve",
    args: [contractAddress, MAX_INT],
    onError: (err) => {
      console.log("err", err);
    }
  });

  const { isLoading: stakeLoading } = useWaitForTransaction({
    hash: data?.hash
  });

  return {
    isLoading: isLoading || stakeLoading,
    allowance,
    approve: writeAsync
  };
};

export default useApprovalToken;
