import { ReactNode } from "react";
import { Styles } from "./Styles";
import Header from "../Header";
import Footer from "../Footer";
import Sidebar from "components/SideBar";

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Styles>
        <Sidebar />
        <div className="children">{children}</div>
      </Styles>
    </>
  );
};

export default Layout;
