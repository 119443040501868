import { ErrorBoundary } from "react-error-boundary";
import { Styles } from "./Styles";
import Button from "components/Button";

function ErrorFallback({ error, resetErrorBoundary }: any) {
  return (
    <Styles role="alert">
      <div className="container">
        <p className="title">Something went wrong:</p>
        <p className="desc">{error.message}</p>
        <Button onClick={resetErrorBoundary}>Try again</Button>
      </div>
    </Styles>
  );
}

const ErrorBoundaryWrap = ({ children }: any) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrap;
