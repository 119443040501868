import { useAccount, useContractRead } from "wagmi";
import ERC20_ABI from "abi/ERC20_ABI.json";
import { useQuery } from "@tanstack/react-query";
import { formatUnits } from "viem";

const useGetBalanceToken = (tokenAddress: any, unit = 18) => {
  const { address } = useAccount();
  const {
    data: balance,
    refetch,
    isLoading: isLoadingGetBalance
  } = useContractRead({
    address: tokenAddress,
    abi: ERC20_ABI,
    functionName: "balanceOf",
    args: [address],
    watch: true,
    enabled: !!tokenAddress && !!address
  });

  const balanceConfig = balance
    ? +formatUnits(balance as bigint, unit) || 0
    : 0;
  const { isLoading, data } = useQuery(
    [tokenAddress, balanceConfig],
    async () => {
      return balanceConfig;
    }
  );

  return {
    refetch,
    data,
    isLoading: isLoading || isLoadingGetBalance
  };
};

export default useGetBalanceToken;
