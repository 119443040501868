import { createGlobalStyle } from "styled-components";
export default createGlobalStyle`
@font-face {
  font-family: "SVN-The Voice Regular";
  src: url("/fonts/SVN-The Voice Regular.ttf");
}
@keyframes float_text_border {
  0%{
    transform: translate(0, 0);
    text-shadow: 1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000, 5px 5px 0px #000;
  }
  50%{
    transform: translate(5px, 5px);
    text-shadow: 1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000, 0px 0px 0px #000;
  }
  100%{
    transform: translate(0, 0);
    text-shadow: 1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000, 5px 5px 0px #000;

  }
}

*,
*:after,
*:before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  font-family: "BaiJamjuree-Regular";
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}
.text-border{
  /* animation: float_text_border 0.6s linear infinite; */
  text-shadow: 1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000, 5px 5px 0px #000;
}

.text-border-small{
  text-shadow: 1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000, 2.5px 2.5px 0px #000;
}

.box-shadow{
  cursor: pointer;
  filter: drop-shadow(rgb(0, 0, 0) 0 5px) drop-shadow( rgb(0, 0, 0) 0 -5px) drop-shadow(rgb(0, 0, 0) -5px 0) drop-shadow(rgb(0, 0, 0) 5px 0) drop-shadow(12px 12px 0px #000);
  transition: all 0.2s;
  transform: translate(0,0);
  &:hover(:not(.not-animate)){
    transform: translate(12px,12px);
    filter: drop-shadow(rgb(0, 0, 0) 0 5px) drop-shadow( rgb(0, 0, 0) 0 -5px) drop-shadow(rgb(0, 0, 0) -5px 0) drop-shadow(rgb(0, 0, 0) 5px 0) drop-shadow(0px 0px 0px #000);
  }

}
.box-shadow-small{
  filter: drop-shadow(8px 8px 0px #000);
}






#root{
  position: relative;
  min-height: 100vh;

}
svg,
img{
  display: block;
  height: auto;
}

p{
}
a{
  text-decoration: none;
  display: inline-block;
}

`;
