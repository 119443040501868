import { Theme, lightTheme } from "@rainbow-me/rainbowkit";

const theme = lightTheme();

export const myCustomTheme: Theme = {
  blurs: {
    ...theme.blurs
  },
  colors: {
    ...theme.colors,
    profileForeground:
      "linear-gradient(70.11deg, rgba(198, 235, 255, 0.5) 0.4%, rgba(219, 238, 255, 0.5) 22.71%, rgba(198, 235, 255, 0.5) 49.68%, rgba(234, 231, 255, 0.5) 75.62%, rgba(247, 247, 221, 0.5) 93.26%), rgb(255, 255, 255)",
    modalBorder: "1px solid #000",
    modalBackdrop: "rgba(0,0,0,0.5)"
  },
  fonts: {
    ...theme.fonts
  },
  radii: {
    actionButton: "0",
    connectButton: "0",
    menuButton: "0",
    modal: "0",
    modalMobile: "0"
  },
  shadows: {
    ...theme.shadows
  }
};
