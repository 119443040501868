import Loader from "components/Loader";
import { Styles } from "./Styles";

const LoadingLayout = () => {
  return (
    <Styles>
      <Loader special={true} />
    </Styles>
  );
};

export default LoadingLayout;
